import React, { useState } from "react";
import Select2Wrapper from "../../components/controls/Select2Wrapper";
import { useDispatch } from "react-redux";
import { getAllCatData } from "../../store/category/categoryAction";
import store from "../../store";
import { getAllUserInfo, updateUserInfo } from "../../store/user/userAction";
import { getAllStateData } from "../../store/state/stateAction";
import { useLoaderData, useParams } from "react-router-dom";
import { promiseToast } from "../../utils/Toast";

const UserForm = () => {
  const userInfo = useLoaderData();
  const userDetails = userInfo.userData;
  const { user_id } = useParams();
  
  const dispatch = useDispatch();
  let iniCatVal;
  if(userDetails.specialDiscount){
    iniCatVal = userDetails.specialDiscount.map((item) => {
      return ({ 
        id: item.categoryId, 
        text: '', 
        discount: item.discount
      });
    })
  }else{
    iniCatVal = [];
  }


    const [selectedCategory, setSelectedCategory] = useState(iniCatVal);

    const [formData, setFormData] = useState({
        user_name:userDetails.username,
        company_name:userDetails.companyName,
        phone:userDetails.number,
        email:userDetails.email,
        state:userDetails.stateId,
        city:userDetails.city,
        pincode:userDetails.pincode,
        address:userDetails.address,
        tier:userDetails.tierType,
        special:userDetails.isSpecial.toString(),
        status:userDetails.isUnderReview,
    });

    const categoryList = userInfo?.catData.map((data) => {
      return (
        {
          id:data.id,
          text:data.name,
        }
      )
    });

    const stateList = userInfo?.stateData.map((data) => {
      return (
        {
          id:data.id,
          text:data.name,
        }
      )
    });


    const handleSelectChange = (value) => {
      const specialDiscount = value.map((data) => {
        const selectCat = selectedCategory.filter((item) => item.id == data.id)[0];
        if(selectCat){
          return ({ id: data.id, text: data.text, discount: selectCat.discount})
        }else{
          return ({ id: data.id, text: data.text, discount: ''})
        }
      })
      setSelectedCategory(specialDiscount);
    };
    
    const formHandler = (event) => {
        setFormData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));

        if(event.target.name === 'special' && event.target.value === "0"){
            setSelectedCategory([]);
        }
    };

    const formSubmit = async () => {
      const selectCat = selectedCategory.map((item) => {
        return ({
          categoryId: parseInt(item.id),
          discount: parseInt(item.discount)
        });
      })
      const formVal = {
        "tierType": formData.tier,
        "number": formData.phone,
        "isUnderReview": formData.status == 1 ? true : false,
        "email": formData.email,
        "username": formData.user_name,
        "companyName": formData.company_name,
        "isSpecial": formData.special == 1 ? true : false,
        "city": formData.city,
        "stateId": parseInt(formData.state),
        "pincode": parseInt(formData.pincode),
        "address": formData.address,
        "specialDiscount": selectCat
      }
      
      await promiseToast(dispatch(updateUserInfo({ user_id, formVal  })), "Updating User Info...","User Data Updated Successfully!");
    };

    const changeDiscount = (val, cat_id) => {      
      setSelectedCategory((prev) =>
        prev.map((item) =>
          item.id === cat_id ? { ...item, discount: val } : item
        )
      );
    }

  return (
    <>
      <div className="page-title">
        <h2>Update User</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg">
            <form action="">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="user_name">Name*</label>
                    <input type="text" id="user_name" name="user_name" value={formData.user_name} className="input-wrapper custom-input" onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="company_name">Company Name*</label>
                    <input type="text" id="company_name" className="input-wrapper custom-input" name="company_name" value={formData.company_name} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="user_email">Email*</label>
                    <input type="email" id="user_email" className="input-wrapper custom-input" name="email" value={formData.email} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-outer">
                    <label htmlFor="phone_no">Mobile No*</label>
                    <input type="number" id="phone_no" className="input-wrapper custom-input" name="phone" value={formData.phone} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-outer">
                    <label htmlFor="">State*</label>
                    <select name="state" onChange={formHandler} value={formData.state}>
                      <option value="">Select State</option>
                      {
                        stateList.map((item, key) => {
                          return (
                            <option key={key} value={item.id}>{item.text}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-outer">
                    <label htmlFor="user_city">City*</label>
                    <input type="text" id="city" className="input-wrapper custom-input" name="city" value={formData.city} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-outer">
                    <label htmlFor="pincode">Pincode*</label>
                    <input type="text" id="pincode" className="input-wrapper custom-input" name="pincode" value={formData.pincode} onChange={formHandler} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-outer">
                    <label htmlFor="address">Address*</label>
                    <textarea
                      name="address"
                      id="address"
                      className="textarea_field"
                      rows="3"
                      cols="3"
                      onChange={formHandler}
                    >{formData.address}</textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-outer">
                    <label htmlFor="tier_type">Tier*</label>
                    <select id="tier_type" name="tier" onChange={formHandler} value={formData.tier}>
                      <option value="">Select Tier</option>
                      <option value="Tier 1">Tier 1</option>
                      <option value="Tier 2">Tier 2</option>
                      <option value="Tier 3">Tier 3</option>
                      <option value="Tier 4">Tier 4</option>
                      <option value="Tier 5">Tier 5</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-outer">
                    <label htmlFor="special">Special*</label>
                    <select name="special" onChange={formHandler} value={formData.special}>
                      <option value="">Select Special</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                </div>
                { formData.special === "1" && 
                    <>
                        <div className="col-md-4">
                        <div className="input-outer">
                            <label htmlFor="category">Category</label>
                            <Select2Wrapper
                                options={categoryList}
                                value={selectedCategory}
                                placeholder="Select Category"
                                onChange={handleSelectChange}
                            />

                        </div>
                        </div>
                        { selectedCategory && selectedCategory.map((catData) => {
                            return (
                                <>
                                    <div className="col-md-6">
                                        <div className="input-outer">
                                            <label htmlFor="discount">Category Name</label>
                                            <input type="text" value={catData.text} id="cat_name" readonly="true" className="input-wrapper custom-input" />
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="input-outer">
                                            <label htmlFor="discount">Discount (%)</label>
                                            <input type="text" value={catData.discount} id="discount" className="input-wrapper custom-input" onChange={(e) => changeDiscount(e.target.value, catData.id)} />
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </>
                }
              </div>
            </form>

          </div>

        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <div className="input-outer">
                <select name="status" onChange={formHandler} value={formData.status}>
                  <option value="1">Pending</option>
                  <option value="0">Approved</option>
                </select>
              </div>
              <button type="submit" className="button-primary" onClick={formSubmit}>
                Update
              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;

export async function fetchUserInfo({ params }){
  const { user_id } = params;

  let catData, stateData, userData;
  let { category , state } = await store.getState();

  let return_user_data = await store.dispatch(getAllUserInfo({user_id}));
  userData = return_user_data.payload.user;

  if(category?.category_data === null){
    let return_cat_data = await store.dispatch(getAllCatData());
    catData = return_cat_data.payload.category;
  }else{
    catData = category.category_data;
  }

  if(state?.state_details === null){
    let return_state_data = await store.dispatch(getAllStateData());
    stateData = return_state_data.payload.states;
  }else{
    stateData = state.state_details;
  }

  return (
    {
      userData:userData,
      catData:catData,
      stateData:stateData,
    }
  );
}