import React, { useCallback, useRef, useState } from "react";
import { DataTableComponent, PrimaryButton } from '../../components';
import { NavLink, useLoaderData } from "react-router-dom";
import EditIcon from "../../assets/images/icons/EditIcon.jsx";
import { getAllCatData } from "../../store/category/categoryAction.js";
import store from "../../store/index.jsx";
import moment from "moment";

const CategoryListing = () => {
  const catRecords = useLoaderData();
  const [catData, setCatData] = useState(catRecords);
  const [searchText, setSearchText] = useState('');
  const selectRef = useRef(null);
  
  const handleReset = useCallback((e) => {
    let initialValues = {
      nameOrder_val: null,
      role_val: null,
      idara_val: null,
      user_type_val: null,
      category_val: null,
    }

    selectRef?.current?.clearValue();
    // fetchEmpData(initialValues)
  }, []);

  const columns = [
    {
      key: "sr_no",
      name: 'Sr. No.',
      cell: row => row.id,
      width: "5%",
    },
    {
      key: "name",
      name: 'Category Name',
      cell: row => <><p>{row.name}</p></>,
    },
    {
      key: "category_image",
      name: 'Image',
      cell: row => <><img className="image_view" src={`${process.env.REACT_APP_BASE_URL}uploads/category/${row.image}`} crossorigin="anonymous" /></>,
      width: "8%",
    },
    {
      key: "product",
      name: 'Products',
      cell: row => <><p>{row.productCount}</p></>,
      width: "8%",
    },
    {
      key: "product",
      name: 'Tier 1',
      cell: row => <><p>{row.tier1 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Tier 2',
      cell: row => <><p>{row.tier2 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Tier 3',
      cell: row => <><p>{row.tier3 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Tier 4',
      cell: row => <><p>{row.tier4 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "product",
      name: 'Tier 5',
      cell: row => <><p>{row.tier5 || '0'}</p></>,
      width: "7%",
    },
    {
      key: "updated_at",
      name: 'Updated On',
      cell: row => moment(row.updatedAt).format('DD MMM, YYYY hh:mm A'),
      width: "16%",
    },
    {
      key: "action",
      name: 'Action',
      cell: row => {
        return (
          <>
            {
            <NavLink to={`/category/edit/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>
            }
          </>
        )
      },
      width: "5%",
    }
  ];

  const formattedData = catData.map(item => ({
    ...item,
    updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A')
  }));

  const filteredData = formattedData.filter(item =>
    Object.values(item).some(value =>
      value !== null && value !== undefined && 
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

    
  return (
    <>
      <div className="page-title">
        <h2>All Categories</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3">
            <input type="search" className="table_search" placeholder="Search Anything.."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-9">
          <div className="filter-section">
            
            <h6 className="w-25 mb-0 pt-1">Total Categories : { filteredData.length }</h6>
            <div className="action-btn">
                <NavLink to="/category/add">
                  <PrimaryButton
                    textTransform={"capitalize"}
                    background={"#fff"}
                    height={"30px"}
                  >
                    Add +
                  </PrimaryButton>
                </NavLink>
            </div>
          </div>
        </div>
        </div>
        <div className="table_wrapper mt-3">
            <DataTableComponent
                columns={columns}
                data={filteredData}
                pagination
            />

        </div>
    </>
  );
};

export default CategoryListing;


export async function fetchCatData() {
  let return_data = await store.dispatch(getAllCatData());
  const user = return_data.payload.category;
  return user || null;
}