import React, { useCallback, useRef, useState } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import FileUpload from "../../components/controls/FileUpload.jsx";
import { useDispatch } from "react-redux";
import { promiseToast } from "../../utils/Toast.jsx";
import { DelBrandInfo, getBrandInfo, ModifyBrandInfo, StoreBrandInfo } from "../../store/brand/brandAction.js";
import store from "../../store/index.jsx";
import Swal from "sweetalert2";

const BrandForm = () => {
  const [imageData, setImageData] = useState(null);
  const navigate = useNavigate();
  const brandInfo = useLoaderData();
  const dispatch = useDispatch();
  
  let brandId = brandInfo?.brandData?.id;
  
  const [formData, setFormData] = useState({
    brand_name:brandInfo?.brandData?.brand_name || '',
    feature_image:brandInfo?.brandData?.feature_image || '',
  });

  const fileVal = (val) => {
    setImageData(val)
  }
  
  const formHandler = (event) => {
    setFormData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value
    }));
  };

  
  const formSubmit = async () => {
    let formVal = new FormData();
    formVal.append('brand_name', formData.brand_name);

    if(brandInfo?.brandData?.id){
      if(imageData){
        formVal.append('feature_image', imageData);
      }

      await promiseToast(dispatch(ModifyBrandInfo({ formVal,brandId })), "Updating Brand Info...","Brand Data Updated Successfully!");
    }else{
      formVal.append('feature_image', imageData);
      await promiseToast(dispatch(StoreBrandInfo({ formVal  })), "Adding Brand Info...","Brand Data Added Successfully!");
    }
  };

  const deleteIInfo = async() => {
      Swal.fire({
        text: "Are you sure, you want to delete this record?",
        showCancelButton: true,
        confirmButtonColor: '#006bcc',
        cancelButtonColor: '#bc3535',
        focusCancel: true,
        fontSize: '20px',
        width: '30em',
        padding: '4em 0 5.25em'
    },
        {
            fontSize: '20px'
        }
    ).then(async (result) => {
        if (result.isConfirmed) {
            await dispatch(DelBrandInfo({ brandId }))
            navigate("/brand")
        }
    })
  }

  return (
    <>
      <div className="page-title">
        <h2>Add Brand</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg custom_height">
            <form action="">
              <div className="row">
                <div className="col-md-9">
                  <div className="input-outer">
                    <label htmlFor="brand_name">Brand Title*</label>
                    <input type="text" className="input-wrapper custom-input" id="brand_name" name="brand_name" value={formData.brand_name} onChange={formHandler} />
                  </div>
                </div>
                
              </div>
            </form>

          </div>
          { brandId &&
          <div className="d-flex gap-2 mt-3">
            <NavLink className="delete_link" onClick={deleteIInfo}>Delete Brand?</NavLink>
            <span className="note">Ensure that this brand is removed from all products.</span>
          </div>
          }
            
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <button type="submit" className="button-primary" onClick={formSubmit}>
              { brandId ? 'Update' : 'Publish Now' } 
              </button>
            </div>

            <div className="form_bg mt-3">
              { brandId ? 
              <FileUpload label="Upload Feature Image" value={`uploads/brand/${formData.feature_image}`} onChange={fileVal} />
              : 
              <FileUpload label="Upload Feature Image"  onChange={fileVal} /> 
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandForm;

export async function fetchBrandInfo({ params }){
  const { brand_id } = params;

  let return_data = await store.dispatch(getBrandInfo({ brand_id }));
  const brandData = return_data.payload.brand;

  if(brand_id){
    return (
      {
        brandData:brandData,
      }
    );
  }else{
    return (
      { }
    );
  }
  
}