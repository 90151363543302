import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllCatData = createAsyncThunk('get/cat', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/category');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const StoreCatInfo = createAsyncThunk('add/cat', async ({ formVal }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('api/category', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getCatInfo = createAsyncThunk('cat_info', async ({ catId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/category/'+catId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ModifyCatInfo = createAsyncThunk('modify/cat', async ({ formVal,catId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.patch('api/category/'+catId, formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const DelCatInfo = createAsyncThunk('del/cat', async ({ catId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/category/'+catId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});