import moment from "moment";

export const getProperty = (obj, keys) => {
    let value = obj;
    for (const key of keys) {
        if (value && value.hasOwnProperty(key)) {
            value = value[key];
        } else {
            value = undefined;
            break;
        }
    }
    return value;
};

export const customFilter = (data, filters, filterKeys) => {
    return data.filter(item => {
        return filters.every(filter => {
            const { value } = filter;
            return filterKeys.some(filterKey => {
                const filterParts = filterKey.split('.');
                const propertyValue = getProperty(item, filterParts);
                if (propertyValue !== undefined && propertyValue !== null && !!propertyValue) {
                    const stringValue = propertyValue?.toString().toLowerCase();
                    if (typeof value === 'number' || !isNaN(Number(value))) {

                        return stringValue.includes(value?.toString());
                    } else if (typeof value === 'string') {
                        return stringValue.includes(value?.toLowerCase());
                    }
                }
                return false;
            });
        });
    });
};

export const imageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) {
            reject(new Error('File is null or undefined.'));
            return;
        }

        if (!(file instanceof Blob)) {
            reject(new Error('Invalid file type. Expected a Blob.'));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

    });
};

export const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat('en-IN').format(number);
};
export const genrate_date_time = (date, type = null) => {
    let returndate = type
        ? { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }
        : { day: '2-digit', month: 'short', year: 'numeric' }

    let formattedDate = new Date(date).toLocaleDateString('en-US', returndate);
    const indexOfSecondComma = formattedDate.indexOf(',', formattedDate.indexOf(',') + 1);

    const modifiedFormattedDate = indexOfSecondComma !== -1
        ? formattedDate.slice(0, indexOfSecondComma) + '  ' + formattedDate.slice(indexOfSecondComma + 1)
        : formattedDate;
    return modifiedFormattedDate;
}
export const calculateDifference = (start_date = null, end_date = null) => {

    const start = start_date ? start_date : new Date();
    const end = end_date ? end_date : new Date();
    const startDate = moment(start);
    const endDate = moment(end);

    const duration_new = moment.duration(endDate.diff(startDate));
    const years = duration_new.years();
    const months = duration_new.months();
    const days = duration_new.days();

    return years + ' Years ' + months + ' Months ' + days + ' Days';
};

export const findOptionByValue = (ids, options) => {
    let setValue = ids;
    if (setValue && typeof setValue === 'string') {
        if (setValue.includes(',')) {
            let allValues = setValue.split(",");
            if (allValues.length > 0) {
                return allValues.map((item) => options.find(option => option.value == item));
            }
        } else {
            return options.find(option => option.value == setValue);
        }
    }
    else {
        return options.find(option => option.value == setValue);
    }

    return null;
};

// src/services/countryService.js
export async function getCountries() {
    const response = await fetch('https://restcountries.com/v3.1/all');
    const countries = await response.json();
    return countries.map(country => ({
        name: country.name.common,
        flag: `https://www.countryflags.io/${country.cca2}/flat/64.png`,
        callingCode: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
        cca2: country.cca2
    }));
}