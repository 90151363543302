
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../pages/Loading';
import HasAccess from '../components/HasAccess';

const PrivateRoute = ({ component: Component, permissions, displayName, access, role, ...props }) => {
	const { accessToken, user } = useSelector(state => state.auth)    
	if (!accessToken) return <Navigate to="/" />;
	if (!user?.role) return <Loading />;
    
	return (
		<HasAccess permissions={permissions} role={role}>
            <Component displayName={displayName} {...props} />
		</HasAccess>
	);
};

export default PrivateRoute;