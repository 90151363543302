import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllLeadData = createAsyncThunk('get/lead', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/lead');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getLeadInfo = createAsyncThunk('lead_info', async ({ lead_id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/lead/'+lead_id);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ModifyLeadInfo = createAsyncThunk('modify/lead', async ({ formVal,leadId, type }, { rejectWithValue }) => {
	try {
		let payload
		if(type === 'product'){
			payload = {
				products: formVal
			}
		}else{
			payload = formVal;
		}
		console.log('payload - ', payload);
		
		const response = await axiosConfig.patch('api/lead/'+leadId, payload);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const DelLeadInfo = createAsyncThunk('del/lead', async ({ leadId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/lead/'+leadId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});