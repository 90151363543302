import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllProductData = createAsyncThunk('get/product', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/product');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const StoreProductInfo = createAsyncThunk('add/product', async ({ formVal }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('api/product', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getProductInfo = createAsyncThunk('product_info', async ({ product_id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/product/'+product_id);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ModifyProductInfo = createAsyncThunk('modify/product', async ({ formVal,productId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.patch('api/product/'+productId, formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const DelProductInfo = createAsyncThunk('del/product', async ({ productId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/product/'+productId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});