import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError();

  return (
      <div className="flex">
        <div
          className="flex-none w-64 bg-blue-600 p-6 text-white"
          style={{ zIndex: 500 }}
        >
          <h1 className="text-3xl font-semibold mb-4">Oops! Something went wrong!</h1>
          <p className="text-sm">Oops! The page you're looking for doesn't exist.</p>
        </div>
        <div className="flex-grow bg-gray-100 p-6">
        <span className="text-2xl font-semibold mb-2">{error?.status || 404}</span>
          <p className="text-gray-600">{error?.message || 'An error occurred.'}</p>
        </div>
      </div>
  );
};

export default ErrorPage;