import * as React from "react"
const CsvIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 150 150",
    }}
    viewBox="0 0 150 150"
    {...props}
  >
    <style>{".csv2{fill-rule:evenodd;clip-rule:evenodd;fill:#005c2f}"}</style>
    <path
      d="M51.1 5h47.4l41.9 41.9v80.4c0 9.8-8 17.8-17.8 17.8H51.1c-9.8 0-17.8-8-17.8-17.8V22.8C33.3 13 41.3 5 51.1 5z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#00733b",
      }}
    />
    <path
      d="m98.5 5 41.9 41.9H103c-2.5 0-4.5-2-4.5-4.5V5zM13.5 64.6H110c2.1 0 3.9 1.8 3.9 3.9v35.4c0 2.1-1.8 3.9-3.9 3.9H13.5c-2.1 0-3.9-1.8-3.9-3.9V68.5c0-2.1 1.8-3.9 3.9-3.9z"
      className="csv2"
    />
    <path
      d="M33.3 81.4V91c0 2.8 2.2 5 5 5s5-2.2 5-5c0-1.2 1-2.3 2.3-2.3 1.2 0 2.3 1 2.3 2.3 0 5.3-4.3 9.5-9.5 9.5s-9.5-4.3-9.5-9.5v-9.7c0-5.3 4.3-9.5 9.5-9.5s9.5 4.3 9.5 9.5c0 1.2-1 2.3-2.3 2.3-1.2 0-2.3-1-2.3-2.3 0-2.8-2.2-5-5-5-2.8.1-5 2.3-5 5.1zm26.7-5h3.6c1.5 0 2.6 1.2 2.6 2.6 0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3 0-3.9-3.2-7.2-7.2-7.2H60c-4.6 0-8.3 3.7-8.3 8.3 0 4.1 2.1 6 3.8 7 2.2 1.2 5 1.3 6.9 1.3 2.1 0 3.8 1.7 3.8 3.8 0 2.1-1.7 3.8-3.8 3.8h-3.6c-1.5 0-2.6-1.2-2.6-2.6 0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3 0 3.9 3.2 7.2 7.2 7.2h3.6c4.6 0 8.3-3.7 8.3-8.3 0-4.6-3.7-8.3-8.3-8.3-5.8 0-6.2-1.7-6.2-3.8 0-2.1 1.7-3.8 3.8-3.8zm33.2-4.5c-1.2-.4-2.5.2-2.9 1.4l-5.9 17.8-5.9-17.8c-.4-1.2-1.7-1.8-2.9-1.4-1.2.4-1.8 1.7-1.4 2.9l8 24.2c.3.9 1.2 1.5 2.1 1.5 1 0 1.8-.6 2.1-1.5l8-24.2c.6-1.2 0-2.5-1.2-2.9z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)
export default CsvIcon
