import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import categoryReducer from './category/categorySlice';
import brandReducer from './brand/brandSlice';
import { injectStore } from '../utils/axiosConfig';
import stateSlice from './state/stateSlice';
import citySlice from './city/citySlice';
import productSlice from './product/productSlice';

const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		category: categoryReducer,
		brand: brandReducer,
		state: stateSlice,
		city: citySlice,
		product: productSlice,
	}
});

injectStore(store);

export default store;