import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllBrandData = createAsyncThunk('get/brand', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/brand');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const StoreBrandInfo = createAsyncThunk('add/brand', async ({ formVal }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('api/brand/add', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getBrandInfo = createAsyncThunk('brand_info', async ({ brand_id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/brand/'+brand_id);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ModifyBrandInfo = createAsyncThunk('modify/brand', async ({ formVal,brandId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.patch('api/brand/edit/'+brandId, formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const DelBrandInfo = createAsyncThunk('del/brand', async ({ brandId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/brand/delete/'+brandId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});