import { useSelector } from "react-redux";

export const useAccessControl = () => {
    const { modules, user } = useSelector(state => state.auth);  

    const checkRoleAccess = (requiredRoles, requiredModules) => {
        if (!Array.isArray(requiredRoles) || !Array.isArray(requiredModules)) {
            console.error('Required roles and modules should be arrays.');
            return false;
        }
        return modules.some(module => {
            return (
                (requiredModules.length === 0 || requiredModules.includes(module.slug)) &&
                (requiredRoles.length === 0 || requiredRoles.includes(user?.role))
            );
        });
    };

    return { checkRoleAccess };
};