// Select2Wrapper.js
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';  // Import default Select2 CSS
import 'select2/dist/js/select2.min.js';

const Select2Wrapper = ({ options, onChange, value, placeholder }) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const $select = $(selectRef.current);

    $select.select2({
      data: options,
      multiple: true,
      placeholder: placeholder,
      width: '100%',
      allowClear: true
    });

    $select.on('change', function () {
      const selectedValues = $(this).val();
      const selectedData = $select.select2('data').map(item => ({
        id: item.id,
        text: item.text
      }));
      if (selectedValues !== value) {
        onChange(selectedData);
      }
    });

    return () => {
      $select.select2('destroy');
    };
  }, [options, onChange, value, placeholder]);

  useEffect(() => {
    const $select = $(selectRef.current);
    if (value && value.length > 0 && $select.val().toString() !== value.map(item => item.id).toString()) {
      $select.val(value.map(item => item.id)).trigger('change');
    }
  }, [value]);

  return <select ref={selectRef} multiple />;
};

export default Select2Wrapper;
