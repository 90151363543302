import React, { useEffect, useState } from 'react'
import { errorToast } from '../../utils/Toast';

const FileUpload = ({ limit, label, onChange, value, filePath, setFormData, ...props }) => {

    let gallVal = [];
    let ImgVal = null;
    if(value && limit > 1){
        let gallValArr = value.split(',');
        gallValArr.map((item) => {
            gallVal.push({ img_url: `${process.env.REACT_APP_BASE_URL}${filePath}/${item}`});
        });
    }else{
        if(value){
            ImgVal = `${process.env.REACT_APP_BASE_URL}${value}`
        }
    }
    const [selectedImage, setSelectedImage] = useState(ImgVal);
    const [selectedGalleryImage, setSelectedGalleryImage] = useState(gallVal);
    const [selectedImageData, setSelectedImageData] = useState(null);
    const [selectedMulImage, setSelectedMulImage] = useState([]);

  const handleImageChange = (event) => {
    let file;
    if(limit){
        let checkLimit;
        if(gallVal.length === 0){
            checkLimit = limit;
        }else{
            checkLimit = limit - selectedGalleryImage.length;
        }
        
        if(event.target.files.length <= checkLimit){
            setSelectedMulImage(event.target.files);
            Array.from(event.target.files).forEach((file) => {
                const reader = new FileReader();
                if(gallVal.length === 0){
                    setSelectedGalleryImage([]);
                }
                reader.onloadend = () => {
                    setSelectedGalleryImage((prevImages) => [...prevImages, { img_url: reader.result }]);
                };
                reader.readAsDataURL(file);                              
            });
        }else{
            errorToast('Maximum 3 images are allowed');
            event.target.value = "";
            return;
        }
    }else{
        file = event.target.files[0];
        setSelectedImage(null)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            setSelectedImageData(file);
        }
    }
  };

  useEffect(() => {
    if(onChange){
        if (limit > 1) {
            onChange(selectedMulImage);
        } else {
            onChange(selectedImageData);
        }
    }
  },[selectedImageData,setSelectedImageData, selectedMulImage, setSelectedMulImage])

  const removeGalleryImage = (filePath) => {
    setFormData((prevData) => ({
        ...prevData,
        ['gallery_img']: null,
      }));    
  }
  return (
    <>
        { label && <label htmlFor="" className="text-center">{label}</label>}
        <input multiple={limit > 1} className="mt-2 file_field" type="file" onChange={handleImageChange} name="" id="" accept=".png,.jpg,.jpeg,.webp" />
        
        {selectedImage && (
            <div className="feature_image">
                <img src={selectedImage} alt="" style={{ width: '100%', height: '100%', objectFit:'cover' }} crossorigin="anonymous"  />
            </div>
        )}
        
        {limit > 1 && (
            <div className="gallery_image">
                {
                    selectedGalleryImage && selectedGalleryImage.map((data, key) => {
                        return (
                            <>
                                <div key={key} className='gall_item position-relative'>
                                    { data.img_url.includes(process.env.REACT_APP_BASE_URL) && <span onClick={(() => removeGalleryImage(data.img_url))}>x</span>}
                                    <img key={key} src={data.img_url} alt="" crossorigin="anonymous" />
                                </div>
                            </>
                        );
                    })
                }
                
            </div>
        )}
    </>
  )
}

export default FileUpload