import React, { useState } from "react";
import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { promiseToast } from "../../utils/Toast.jsx";
import store from "../../store/index.jsx";
import Swal from "sweetalert2";
import {
  DelAccessInfo,
  getAccessInfo,
  getAllAccessData,
  getAllAccessRecords,
  ModifyAccessInfo,
  StoreAccessInfo,
} from "../../store/access/accessAction.js";
import { getAllUserData } from "../../store/user/userAction.js";
import Select2Wrapper from "../../components/controls/Select2Wrapper.jsx";

const AccessForm = () => {
  const navigate = useNavigate();
  const accessInfo = useLoaderData();  
  const dispatch = useDispatch();

  let accessId = accessInfo?.accessData?.id;

  const [formData, setFormData] = useState({
    user_id: accessInfo?.accessData?.id || "",
  });

  let iniCatVal;
  if (accessInfo?.accessData) {
    iniCatVal = accessInfo?.accessData.modules.map((item) => {
      return {
        id: item.id,
        text: item.name,
      };
    });
  } else {
    iniCatVal = [];
  }
  const [selectedModule, setSelectedModule] = useState(iniCatVal);
  const ModuleList = accessInfo?.moduleData.map((data) => {
    return {
      id: data.id,
      text: data.name,
    };
  });

  const UserList = accessInfo?.userData.map((data) => {
    return {
      id: data.id,
      text: data.username,
    };
  });

  const formHandler = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSelectChange = (value) => {
    const specialDiscount = value.map((data) => {
      return { id: data.id, text: data.text };
    });
    setSelectedModule(specialDiscount);
  };

  const formSubmit = async () => {
    const selectedVal = selectedModule.map((item) => {
      return {
        moduleId: parseInt(item.id),
      };
    });
    const formVal = {
      userId: parseInt(formData.user_id),
      modules: selectedVal,
    };

    if (accessInfo?.accessData?.id) {
      await promiseToast(
        dispatch(ModifyAccessInfo({ formVal, accessId })),
        "Updating Access Info...",
        "Access Data Updated Successfully!"
      );
    } else {
      await promiseToast(
        dispatch(StoreAccessInfo({ formVal })),
        "Adding Access Info...",
        "Access Data Added Successfully!"
      );
    }
    navigate("/access-panel");
  };

  const deleteIInfo = async () => {
    Swal.fire(
      {
        text: "Are you sure, you want to delete this record?",
        showCancelButton: true,
        confirmButtonColor: "#006bcc",
        cancelButtonColor: "#bc3535",
        focusCancel: true,
        fontSize: "20px",
        width: "30em",
        padding: "4em 0 5.25em",
      },
      {
        fontSize: "20px",
      }
    ).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(DelAccessInfo({ accessId }));
        navigate("/brand");
      }
    });
  };

  return (
    <>
      <div className="page-title">
        <h2>{accessId ? "Update" : "Add"} Access</h2>
      </div>
      <div className="row form_space">
        <div className="col-md-9 ps-0">
          <div className="form_bg custom_height">
            <form action="">
              <div className="row">
                <div className="col-md-3">
                  <div className="input-outer">
                    <label htmlFor="">User*</label>
                    <select
                      name="user_id"
                      onChange={formHandler}
                      value={formData.user_id}
                    >
                      <option value="">Select User</option>
                      {UserList.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="input-outer">
                    <label htmlFor="">Modules</label>
                    <Select2Wrapper
                      options={ModuleList}
                      value={selectedModule}
                      placeholder="Select Module"
                      onChange={handleSelectChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* {accessId && (
            <div className="d-flex gap-2 mt-3">
              <NavLink className="delete_link" onClick={deleteIInfo}>
                Delete Access?
              </NavLink>
            </div>
          )} */}
        </div>
        <div className="col-md-3  pe-0">
          <div className="right_sidebar">
            <div className="form_submit">
              <button
                type="submit"
                className="button-primary"
                onClick={formSubmit}
              >
                {accessId ? "Update" : "Publish Now"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessForm;

export async function fetchAccessInfo({ params }) {
  const { access_id } = params;
  let return_module_data = await store.dispatch(getAllAccessData());
  const module = return_module_data.payload.modules;

  let return_user_data = await store.dispatch(getAllUserData());
  const user = return_user_data.payload.user;


  if (access_id) {
    let return_access_data = await store.dispatch(getAccessInfo({ access_id }));
    const access = return_access_data.payload.access;

    return {
      moduleData: module,
      userData: user,
      accessData: access
    };
  } else {
    
    let access_data = await store.dispatch(getAllAccessRecords());
    const access_record = access_data.payload.access;   

    const mismatchValues = user.filter(userItem => {
      return !access_record.some(moduleItem => moduleItem.id === userItem.id);
    });
    
    return {
      moduleData: module,
      userData: mismatchValues
    };
  }
}
