import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getAllBannerData = createAsyncThunk('get/banner', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/banner');
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const StoreBannerInfo = createAsyncThunk('add/banner', async ({ formVal }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('api/banner', formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getBannerInfo = createAsyncThunk('banner_info', async ({ banner_id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('api/banner/'+banner_id);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const ModifyBannerInfo = createAsyncThunk('modify/banner', async ({ formVal,bannerId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.patch('api/banner/'+bannerId, formVal);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const DelBannerInfo = createAsyncThunk('del/banner', async ({ bannerId }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.delete('api/banner/'+bannerId);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});