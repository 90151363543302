import { createSlice } from '@reduxjs/toolkit';
import { DelBrandInfo, getAllBrandData, getBrandInfo, ModifyBrandInfo, StoreBrandInfo } from './brandAction';

const initialState = {
	loading: false,
	brand_data: null,
	filterText: {},
	error: null,
};

const brandSlice = createSlice({
	name: 'brand',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllBrandData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBrandData.fulfilled, (state, action) => {
				state.loading = false;
				state.brand_data = action.payload.brand;
				state.error = null;
			})
			.addCase(getAllBrandData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(StoreBrandInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(StoreBrandInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(StoreBrandInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(getBrandInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(getBrandInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(getBrandInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(ModifyBrandInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(ModifyBrandInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(ModifyBrandInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(DelBrandInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(DelBrandInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(DelBrandInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	},
});

export default brandSlice.reducer;