import React from 'react';
import { PDFViewer, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    // General table layout with outer border and rounded corners
    table: {
      display: 'table',
      width: 'auto',
      marginTop: 20,
      borderStyle: 'solid',
      borderWidth: 0,
      borderColor: 'red',
      borderRadius: 10, // for rounded corners on outer table
    },
    // Header row with gray background and rounded top corners
    tableRowHeader: {
      flexDirection: 'row',
      backgroundColor: '#B3B3B3',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    // Style for header cells with fixed widths
    tableColHeader: {
      padding: 8,
      fontSize: 10,
      color: 'black',
      borderRightWidth: 0,
      borderRightColor: '#E0E0E0',
    },
    // Table body row with borders and fixed widths
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#E0E0E0',
    },
    tableCol: {
      padding: 10,
      fontSize: 10,
    //   textAlign: 'center',
      borderRightWidth: 1,
      borderRightColor: '#E0E0E0',
    },
    tableColLast: {
      padding: 10,
      fontSize: 10,
    //   textAlign: 'center',
    },
    // Fixed column widths (adjust according to your needs)
    colId: {
      width: '10%', // ID column width
    },
    colItem: {
      width: '40%', // ITEM column width
    },
    colQty: {
      width: '10%', // QTY column width
    },
    colUom: {
      width: '10%', // UOM column width
    },
    colPerPc: {
      width: '15%', // PER PC column width
    },
    colTotal: {
      width: '15%', // TOTAL column width
    },
  });
  
  // Create a Table Component to match the design with fixed column widths
  const LeadPdf = ({ pdfData }) => (   
    
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Table */}
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRowHeader}>
            <Text style={[styles.tableColHeader, styles.colId]}>ID</Text>
            <Text style={[styles.tableColHeader, styles.colItem]}>ITEM</Text>
            <Text style={[styles.tableColHeader, styles.colQty]}>QTY</Text>
            <Text style={[styles.tableColHeader, styles.colUom]}>UOM</Text>
            <Text style={[styles.tableColHeader, styles.colPerPc]}>PER PC</Text>
            <Text style={[styles.tableColHeader, styles.colTotal]}>TOTAL</Text>
          </View>
  
          {/* Table Body */}
          { pdfData?.map((item, key) => {
            return (
              <>
              <View style={styles.tableRow} key={key}>
                <Text style={[styles.tableCol, styles.colId]}>{ item.uid }</Text>
                <Text style={[styles.tableCol, styles.colItem]}>{ item.title }</Text>
                <Text style={[styles.tableCol, styles.colQty]}>{ item.qty }</Text>
                <Text style={[styles.tableCol, styles.colUom]}>{ item.unit }</Text>
                <Text style={[styles.tableCol, styles.colPerPc]}>{ item.price }</Text>
                <Text style={[styles.tableColLast, styles.colTotal]}>{ parseInt(item.qty) * parseInt(item.price) }</Text>
              </View>
              </>
            )
          }) }
        </View>
      </Page>
    </Document>
  );


  export default LeadPdf;  