import React, { useRef, useState } from "react";
import { InputField, PrimaryButton } from "../../components";
import { NavLink } from "react-router-dom";
import { isValidUsername, validateEmail } from "../../Validation/Validation";
import { forgotPassword } from "../../store/auth/authActions";
import { useDispatch } from "react-redux";
import { errorToast, promiseToast } from "../../utils/Toast";
import { toast, ToastContainer } from "react-toastify";


const Forgot = () => {
	const dispatch = useDispatch()
	const ref = useRef(null)
	const [email, setEmail] = useState('');
	const [userExist, setUserExist] = useState(2);

	const handleEmail = async (e) => {
		const email = e.target.value.trim()
		setEmail(email);
		if (!email.length) return setUserExist(2);
		if (!isValidUsername(email)) {
			return setUserExist(0);
		} else {
			return setUserExist(2);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!validateEmail(email)) return errorToast("Invalid email address. Please check your input and try again.");
		let res = await promiseToast(dispatch(forgotPassword({ email })), "Loading...")
		if (res?.error){
		  return setUserExist(0);
		}
		setEmail('');
		// ref.current.click();
	};

	return (
		<div className="login-wrapper">
      <ToastContainer />
			<div className="login-outer">
				<div className="login-inner">
					<div className="login-logo">
                        <img src="images/main-logo.png" />
					</div>
					<form className="input-fields" onSubmit={handleSubmit}>
						<InputField
							id="email"
							type={'email'}
							label={"Enter Email ID "}
							value={email}
							onChange={handleEmail}
							valid={userExist}
							placeholder="Type your email"
							required
						/>
						<PrimaryButton type="submit"  >
							Submit
						</PrimaryButton>
					</form>
					<div className="forget-pass">
						<NavLink to={'/'} ref={ref} >Back To Login</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Forgot;