import React from "react";
import { DataTableComponent, PrimaryButton } from '../../components';
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <div className="page-title">
        <h2>Dashboard</h2>
      </div>
    </>
  );
};

export default Dashboard;