import React from "react";
import { Sidebar as ReactSideBar, Menu, MenuItem } from "react-pro-sidebar";
import "./layout.css";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import {  PrimaryButton } from "../components";
import { logoutUser } from "../store/auth/authActions";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  const { modules } = useSelector(state => state.auth);

  const module_new = modules.map((item) => {
    return {
      permissions: '',
      to:item.slug,
      icon:'',
      title:item.name
    }
  })
	
  const navigate = useNavigate();
  const dispatch = useDispatch();

	const logoutHandler = async () => {
        Swal.fire({
            text: "Are you sure, you want to log out?",
            showCancelButton: true,
            confirmButtonColor: '#006bcc',
            cancelButtonColor: '#bc3535',
            focusCancel: true,
            fontSize: '20px',
            width: '30em',
            padding: '4em 0 5.25em'
        },
            {
                fontSize: '20px'
            }
        ).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(logoutUser())
                navigate("/")
            }
        })
    };

  return (
    <>
      <div className="sidebar-container">
        <div className="logo">
			<NavLink to="/">
				<img src="/images/main-logo.png" />
			</NavLink>
        </div>
        <div className="sidebar">
          <ReactSideBar
            width="100%"
            menuItemStyles={{
              height: "30px",
            }}
            breakPoint="md"
            rootStyles={{
              border: "none",
              width: "100%",
              overflowY: "auto",
            }}
          >
            <Menu className="sidebar-menu">
              {module_new.map(({ permissions, to, icon, title }, index) => {
               return (
                <>
                    {index === 0 && (
                        <MenuItem key={index} className="menu-items" component={<NavLink to={'/'} />} icon={icon}>
                            {'Dashboard'}
                        </MenuItem>
                    )}
                    <MenuItem key={index} className="menu-items" component={<NavLink to={to} />} icon={icon}>
                        {title}
                    </MenuItem>
                </>
            );
				})}
            </Menu>
          </ReactSideBar>
        </div>
        <div className="sidebar-footer">
          <PrimaryButton
            type="button"
            width="100%"
            onClick={logoutHandler}
          >
            LOGOUT
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
