import React, { useState } from "react";
import { NavLink, useLoaderData } from "react-router-dom";
import { PrimaryButton } from "../../components";
import { EditIcon } from "../../assets/images/icons";
import FileUpload from "../../components/controls/FileUpload";
import { useDispatch } from "react-redux";
import { getAllCatData } from "../../store/category/categoryAction";
import store from "../../store";
import { promiseToast } from "../../utils/Toast";
import {
  getAllBannerData,
  StoreBannerInfo,
} from "../../store/banner/bannerAction";

const BannerListing = () => {
  const dispatch = useDispatch();
  const bannerInfo = useLoaderData();

  const [formData, setFormData] = useState({
    category: "",
    product: "",
    type: "",
    banner_image: "",
  });
  const [imageData, setImageData] = useState(null);

  const formHandler = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const fileVal = (val) => {
    setImageData(val);
  };

  const categoryList = bannerInfo?.catData.map((data) => {
    return {
      id: data.id,
      text: data.name,
    };
  });

  const submitform = async () => {
    let formVal = new FormData();
    formVal.append(
      "refId",
      parseInt(
        formData.type === "category" ? formData.category : formData.product
      )
    );
    formVal.append("type", formData.type);
    formVal.append("image", imageData);

    await promiseToast(
      dispatch(StoreBannerInfo({ formVal })),
      "Adding Banner...",
      "Banner Added Successfully!"
    );
  };

  const editPopup = (row_id) => {
    const currentBanner = bannerInfo.bannerData.filter(
      (data) => data.id === row_id
    )[0];

    setFormData({
      category: currentBanner.type === "category" && currentBanner.refId,
      product: currentBanner.type === "product" && currentBanner.refId,
      type: currentBanner.type,
      banner_image: currentBanner.image,
    });
  };
  console.log(formData, " -formData");

  const addPopup = () => {
    setFormData({
      category: "",
      product: "",
      type: "",
      banner_image: "",
    });
  };

  return (
    <>
      <div className="page-title">
        <h2>All Banner</h2>
      </div>
      <div className="row listing_wrapper">
        <div className="col-md-3"></div>
        <div className="col-md-9">
          <div className="filter-section">
            <div className="action-btn">
              <NavLink
                to=""
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <PrimaryButton
                  textTransform={"capitalize"}
                  background={"#fff"}
                  height={"30px"}
                  onClick={addPopup}
                >
                  Add +
                </PrimaryButton>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="listing_data_2 mt-3">
        <div className="row">
          {bannerInfo.bannerData &&
            bannerInfo.bannerData.map((data) => {
              return (
                <>
                  <div className="col-md-4">
                    <div className="banner_image">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}uploads/banner/${data.image}`}
                        alt=""
                        crossorigin="anonymous"
                      />
                      <div className="hover_item">
                        <NavLink
                          to=""
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="action-icon"
                          aria-label="edit"
                          onClick={() => editPopup(data.id)}
                        >
                          {" "}
                          <EditIcon height={15} width={15} />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0">
              <h4 className="modal-title" id="exampleModalLabel">
                { formData.banner_image ? 'Update' : 'Add'} Banner Image
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0">
              <div className="row align-items-center">
                <div className="col-md-6 form_bg min-height-custom">
                  { formData.banner_image && <FileUpload
                    label="Upload Banner Image"
                    value={`uploads/banner/${formData.banner_image}`}
                    onChange={fileVal}
                  />
                  }

                  { !formData.banner_image && <FileUpload
                    label="Upload Banner Image"
                    onChange={fileVal}
                  />
                  }
                  
                </div>
                <div className="col-md-6 form_bg bg-white">
                  <div className="input-outer">
                    <label htmlFor="type">Banner Type</label>
                    <select
                      name="type"
                      id="type"
                      onChange={formHandler}
                      value={formData.type}
                    >
                      <option value="">Select Banner Type</option>
                      <option value="category">Category</option>
                      <option value="product">Product</option>
                    </select>
                  </div>
                  {formData.type === "category" && (
                    <div className="input-outer">
                      <label htmlFor="category">Category</label>
                      <select
                        name="category"
                        id="category"
                        onChange={formHandler}
                        value={formData.category}
                      >
                        <option value="">Select Category</option>
                        {categoryList.map((item) => {
                          return <option value={item.id}>{item.text}</option>;
                        })}
                      </select>
                    </div>
                  )}
                  {formData.type === "product" && (
                    <div className="input-outer">
                      <label htmlFor="product">Product</label>
                      <select
                        name="product"
                        id="product"
                        onChange={formHandler}
                        value={formData.product}
                      >
                        <option value="">Select Product</option>
                      </select>
                    </div>
                  )}
                </div>
                <div className="col-md-12 text-center mt-3">
                  <button
                    type="button"
                    className="edit_button"
                    onClick={submitform}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerListing;

export async function fetchBannerInfo() {
  let catData, productData;

  let return_cat_data = await store.dispatch(getAllCatData());
  catData = return_cat_data.payload.category;

  let return_banner_data = await store.dispatch(getAllBannerData());
  let bannerData = return_banner_data.payload.banner;

  return {
    catData: catData,
    bannerData: bannerData,
  };
}
